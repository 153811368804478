const MILLISECONDS_PER_SECOND = 1000;

export const TUTOR_AVAILABILITY_START_HOUR_EASTERN = 7;
export const TUTOR_AVAILABILITY_END_HOUR_EASTERN = 21;
export const TUTOR_AVAILABILITY_HIGH_DEMAND_TIMES_EASTERN = [
  "08:45",
  "09:00",
  "09:15",
  "09:30",
  "09:45",
  "11:45",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "14:15",
];
export const TUTOR_MAX_HOURS_PER_DAY = 6.0;
export const TUTOR_MIN_HOURS_PER_DAY = 1.0;

export const PAGY_UPPER_PAGE_BOUND = 5;

export const TUTOR_CALENDAR_WEEKLY_VIEW_TIME_BLOCK_MIN_HEIGHT_PX = 30;
export const TUTOR_CALENDAR_TIME_BLOCK_DURATION_MINUTES = 15;

export const EASTERN_TIME_ZONE = "America/New_York";

export const HOUR_MINUTE_TWELVE = "h:mm"; // 9:34
export const HOUR_MINUTE_ZERO_PADDED = "HH:mm"; // 17:45
export const MERIDIEM_HOUR_FORMAT = "h a"; // 8 AM
export const MERIDIEM_TIME_FORMAT = "h:mm a"; // 8:34 AM
export const MERIDIEM_TIME_FORMAT_ZERO_PADDED = "hh:mm a"; // 08:34 AM

export const DAY_WITH_SHORT_MONTH = "d MMM"; // 31 May
export const DEFAULT_DATE_FORMAT = "yyyy-MM-dd"; // 2023-06-21
export const STANDARD_DATE_FORMAT = "yyyy-MM-dd'T'H:mm:ss.SSS'Z'"; // 2023-06-21T13:09:00.000Z
export const SHORT_DATE = "MM/dd/yyyy"; // 06/21/2023
export const SHORT_DATE_WITH_TIME = "MM/dd/yyyy HH:mm"; // 6/25/22 13:09
export const MONTH_DAY_YEAR_DATE_FORMAT = "MMMM d, yyyy"; // November 8, 2022
export const MONTH_NAME_WITH_YEAR = "MMMM yyyy"; // August 2023
export const SHORT_DAY_OF_WEEK_MONTH_AND_DAY = "iii, MMM d"; // Wed, Jun 3
export const SHORT_MONTH_AND_DAY = "MMM d"; // Jun 3
export const DATE_WITH_TIME = "MMMM d, yyyy 'at' h:mmaaa"; // May 23, 2024 at 8:31am

export const SHORT_DAY_OF_WEEK = "iii"; // Wed
export const DAY_OF_MONTH = "d"; // 12

export const CALENDAR_DAILY_VIEW = "daily";
export const CALENDAR_MONTLY_VIEW = "monthly";
export const CALENDAR_WEEKLY_VIEW = "weekly";

export const CALENDAR_NORMAL_MODE = "normal";
export const CALENDAR_SUB_MODE = "sub";

export const TOOLTIP_CLOSE_DELAY_IN_MS = 120;
export const TOOLTIP_DEFAULT_Y_AXIS_OFFSET = 6;
export const TOOLTIP_DEFAULT_SHIFT_PADDING = 5;
export const TOOLTIP_OPEN_DELAY_IN_MS = 40;

export const DEFAULT_REACT_QUERY_STALE_TIME = 20 * MILLISECONDS_PER_SECOND;

export const Q_RETEACH_PLAN_URI =
  "https://ignite-reading.bettermode.io/pdfs-by-lesson/post/bak-pdf-wFZklB1oSgquifh";
export const RIPPLING_URL = "https://www.rippling.com/";

export const tutorStudentStatus = {
  INVITED: "invited",
  ACCEPTED: "accepted",
  REJECTED: "rejected",
  EXPIRED: "expired",
  CANCELLED: "cancelled",
  ENDED: "ended",
  SUB_REQUESTED: "sub_requested",
  CLAIMED: "claimed",
};

export const cancellationReason = {
  LAST_MINUTE: "last_minute_cancellations",
  NO_PROGRAM: "no_program",
  TIME_OFF: "time_off",
};

export const assignmentTypes = {
  PRIMARY: "primary",
  SUB_LONG_TERM: "sub_long_term",
  SUB_SHORT_TERM: "sub_short_term",
};

export const sessionChangeTriggerEventTypes = {
  TUTOR_ACCEPTS_INVITATION: "tutor_accepts_invitation",
  ADMIN_PERMANENTLY_PAIRS: "admin_permanently_pairs",
  TUTOR_STUDENT_PAIRING_CANCELLED: "tutor_student_pairing_cancelled",
  TUTOR_TERMINATED: "tutor_terminated",
  TUTOR_REQUESTS_TIME_OFF: "tutor_requests_time_off",
  STUDENT_LEAVES_PROGRAM: "student_leaves_program",
  STUDENT_MOVED_TUTORING_BLOCK: "student_moved_tutoring_block",
  CLASSROOM_NON_INSTRUCTIONAL_DAY_ADDED: "classroom_non_instructional_day_added",
  SCHOOL_NON_INSTRUCTIONAL_DAY_ADDED: "school_non_instructional_day_added",
  SCHOOL_SYSTEM_NON_INSTRUCTIONAL_DAY_ADDED: "school_system_non_instructional_day_added",
  IGNITE_NON_INSTRUCTIONAL_DAY_ADDED: "ignite_non_instructional_day_added",
  CLASSROOM_NON_INSTRUCTIONAL_DAY_REMOVED: "classroom_non_instructional_day_removed",
  SCHOOL_NON_INSTRUCTIONAL_DAY_REMOVED: "school_non_instructional_day_removed",
  SCHOOL_SYSTEM_NON_INSTRUCTIONAL_DAY_REMOVED: "school_system_non_instructional_day_removed",
  IGNITE_NON_INSTRUCTIONAL_DAY_REMOVED: "ignite_non_instructional_day_removed",
  PROGRAM_TERM_END_DATE_UPDATED: "program_term_end_date_updated",
  PROGRAM_ENROLLMENT_START_DATE_UPDATED: "program_enrollment_start_date_updated",
  REACTIVATE_PENDING_PROGRAM_ENROLLMENT: "reactivate_pending_program_enrollment",
  TUTOR_REQUESTS_TIME_OFF_REMOVED: "tutor_requests_time_off_removed",
  TUTOR_REQUESTS_SUB: "tutor_requests_sub",
  TUTOR_REQUESTS_SUB_CANCELLED: "tutor_requests_sub_cancelled",
  TUTOR_CLAIMS_SUB_REQUEST: "tutor_claims_sub_request",
};

export const readingAssessmentStatusType = {
  CREATED: "created",
  IN_PROGRESS: "in_progress",
  COMPLETED: "completed",
};

export const readingAssessmentItemType = {
  HFW_WORD: "hfw_word",
  NEW_LINE: "new_line",
  PUNCTUATION: "punctuation",
  SKILL_WORD: "skill_word",
  TITLE: "title",
  UNKNOWN_WORD: "unknown_word",
  VOCAB_WORD: "vocab_word",
  WORD: "word",
};

export const readingAssessmentTaskType = {
  CHECKBOX: "checkbox",
  ONE_MINUTE_CHALLENGE: "one_minute_challenge",
  QUESTIONS: "questions",
};

export const studentOneMinuteChallengeType = {
  StudentOneMinuteChallenge: "StudentOneMinuteChallenge",
  StudentNonsenseOneMinuteChallenge: "StudentNonsenseOneMinuteChallenge",
  StudentLetterNameDrill: "StudentLetterNameDrill",
  StudentConditionalLetterNameDrill: "StudentConditionalLetterNameDrill",
  StudentLetterSoundDrill: "StudentLetterSoundDrill",
  InitialSoundSorting: "initial_sound_sorting",
  SayItAndMoveIt: "say_it_and_move_it",
  OralBlending: "oral_blending",
  OralSegmenting: "oral_segmenting",
  TeachingLetterPatterns: "teaching_letter_patterns",
  LetterPatternMatching: "letter_pattern_matching",
  StudentNonsenseWordFluency: "StudentNonsenseWordFluency",
  StudentLetterNameFluency: "StudentLetterNameFluency",
  StudentNonsenseWordFluencyDEAR: "StudentNonsenseWordFluencyDEAR",
};

export const tutorTimeOffStatus = {
  APPROVED: "approved",
  PENDING: "pending",
};
