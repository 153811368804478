import React from "react";
import { useQuery } from "@tanstack/react-query";

import cx from "classnames";
import { get } from "../../api";
import { twMerge } from "tailwind-merge";
import BookOpenSolid from "assets/icons/book-open-solid.svg";
import CheckCircleMini from "assets/icons/check-circle-mini.svg";
import XCircleMini from "assets/icons/x-circle-mini.svg";
import ProgressGraph from "./ProgressGraph";

const TabAssessment = ({ id, paths, shouldDisplayAssessmentComponentDescription }) => {
  const query = useQuery({
    queryKey: ["student-assessment-results", id],
    queryFn: async ({ signal }) => {
      const response = await get(paths.assessmentResults, { signal });

      return response.data;
    },
  });

  const progressGraphData = (perMinuteCountsByScreener, perMinuteUnit, goalCount) => {
    const parsedCounts = JSON.parse(perMinuteCountsByScreener);
    const progressItems = parsedCounts.map((item) => ({
      orderIndex: item.orderIndex,
      title: `${item.name} ${item.count} ${perMinuteUnit}`,
      progress: item.count,
    }));

    return {
      progressItems: progressItems,
      goals: JSON.parse(goalCount),
    };
  };

  if (!query.data) {
    if (query.isError) {
      return (
        <span className="flex items-center justify-center grow text-red-600 min-h-[200px]">
          Error: {query.error.message}
        </span>
      );
    }

    return (
      <span className="flex items-center justify-center grow text-gray-400 min-h-[200px]">
        Loading...
      </span>
    );
  }

  return (
    <div
      className="pt-4 lg:pt-10 text-xs sm:text-sm"
      data-testid="results-drawer-assessment-components"
    >
      {query.data.map(
        ({
          isScreener,
          id,
          name,
          description,
          errorCount,
          goalCount,
          totalCount,
          perMinuteCountsByScreener,
          correctPerMinute,
          perMinuteUnit,
          incomplete,
          assessmentAnswers,
        }) =>
          isScreener ? (
            incomplete ? null : (
              <div className="border rounded-lg border-gray-300 overflow-hidden mb-8" key={id}>
                <div className="p-3 text-center border-b border-gray-300 font-semibold bg-gray-50">
                  <span className="relative">
                    {name}
                    <span
                      className={twMerge(
                        cx(
                          "text-xs text-center whitespace-nowrap rounded-full absolute right-[-114px] top-[-4px] px-4 py-1 font-semibold uppercase bg-purple-100 w-[96px]"
                        )
                      )}
                    >
                      {correctPerMinute} {perMinuteUnit}
                    </span>
                  </span>
                </div>
                <div className="flex flex-col p-5 items-center">
                  <dl className="flex flex-row">
                    <dt className="flex flex-row w-[200px] mx-5 items-center">
                      <CheckCircleMini className="w-6 h-6 mr-2" />
                      Total Correct: {correctPerMinute} {perMinuteUnit}
                    </dt>
                    <dt className="flex flex-row w-[180px] mx-5 items-center">
                      <BookOpenSolid className="w-6 h-6 mr-2" />
                      Total Attempted: {totalCount}
                    </dt>
                    <dt className="flex flex-row mx-5 items-center">
                      <XCircleMini className="w-6 h-6 mr-2" />
                      Errors: {errorCount}
                    </dt>
                  </dl>
                  <ProgressGraph
                    progressData={progressGraphData(
                      perMinuteCountsByScreener,
                      perMinuteUnit,
                      goalCount
                    )}
                  />
                </div>
              </div>
            )
          ) : (
            <div className="border rounded-lg border-gray-300 overflow-hidden mb-8" key={id}>
              <div className="p-3 text-center border-b border-gray-300 font-semibold bg-gray-50">
                {name}
              </div>
              {description && shouldDisplayAssessmentComponentDescription ? (
                <div className="p-3 border-b border-gray-300 text-gray-500 whitespace-normal">
                  {description}
                </div>
              ) : null}
              <div className="grid grid-cols-3 md:grid-cols-4 p-3">
                {assessmentAnswers.map(
                  ({ answerGiven, content, correct, id, incorrect }, index) => (
                    <div className="flex-col p-3 items-center text-gray-900" key={id}>
                      <div className="flex items-center space-x-1.5 font-semibold">
                        <span>
                          {index + 1}. {content}
                        </span>
                        {correct ? (
                          <span className="h-5 w-5 rounded-full bg-green-100 text-green-800 flex items-center justify-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-full w-full"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="1.5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </span>
                        ) : incorrect ? (
                          <span className="h-5 w-5 rounded-full bg-red-100 text-red-800 flex items-center justify-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-full w-full"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="1.5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </span>
                        ) : null}
                      </div>
                      {answerGiven ? <em>{answerGiven}</em> : null}
                    </div>
                  )
                )}
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default TabAssessment;
