import React from "react";

import Navigation from "../Student/Navigation";
import ProtocolList from "./ProtocolList";
import StudentAssessmentResultModalTrigger from "../StudentAssessmentsResults/StudentAssessmentResultModalTrigger";
import PlacedOutsideBanner from "../common/PlacedOutsideBanner";
import UpcomingMopBanner from "../UpcomingMopBanner";
import UpcomingEopBanner from "../UpcomingEopBanner";
import RehearsalBanner from "../common/RehearsalBanner";
import GraduationBanner from "../common/GraduationBanner";

const StudentProgress = () => {
  const params = new URLSearchParams(window.location.search);
  const studentAssessmentIdFromURLParams = parseInt(params.get("studentAssessmentId"), 10);

  return [
    <style data-testid="root-css-vars" key="style">
      {`:root {
        --below-header-offset-top: 119px;
        --md-below-header-offset-top: 125px;
        --lg-below-header-offset-top: 64px;
        --timer-sticky-top: 175px;
        --md-timer-sticky-top: 181px;
        --lg-timer-sticky-top: 120px;
      }`}
    </style>,
    <Navigation key="navigation" />,
    <UpcomingMopBanner key="upcoming-mop" />,
    <UpcomingEopBanner key="upcoming-eop" />,
    <PlacedOutsideBanner key="place-out" />,
    <div className="pt-4" key="rehearsal-banner">
      <RehearsalBanner />
    </div>,
    <GraduationBanner key="graduation-banner" />,
    <ProtocolList key="protocol-list" />,
    studentAssessmentIdFromURLParams ? (
      <StudentAssessmentResultModalTrigger
        key="results-drawer"
        studentAssessmentId={studentAssessmentIdFromURLParams}
      />
    ) : null,
  ];
};

export default StudentProgress;
