import React from "react";
import PropTypes from "prop-types";

import CalendarOutline from "assets/icons/calendar-outline.svg";
import XMarkMini from "assets/icons/x-mark-mini.svg";
import { useSparkCookie } from "../common/SparkCookie";

const UpcomingEventBanner = ({ visible, testId, dismissedText, cookie, children }) => {
  const [isDismissed, setIsDismissed] = useSparkCookie(cookie, "false");
  const onDismiss = () => {
    setIsDismissed("true");
  };
  const onShowBanner = () => {
    setIsDismissed("false");
  };
  return visible ? (
    isDismissed === "true" ? (
      <div className="flex justify-end">
        <button
          className="inline-flex items-center text-sm text-brand-600 hover:text-zinc-800 hover:underline font-semibold mt-6 mx-4"
          data-testid={`show-${testId}`}
          onClick={onShowBanner}
        >
          {dismissedText}
        </button>
      </div>
    ) : (
      <div
        className="flex items-center rounded-lg border border-brand-500 bg-brand-50 px-4 py-5 space-x-4 mt-6 relative mx-4 md:mx-0"
        data-testid={testId}
      >
        <div className="grow-0">
          <div className="rounded-full bg-brand-100 p-1.5">
            <CalendarOutline className="w-5 h-5" />
          </div>
        </div>
        <div className="space-y-2">{children}</div>
        <button
          className="flex items-center space-x-1.5 text-xs text-gray-500 hover:text-gray-700 hover:underline absolute top-3 right-5"
          data-testid={`dismiss-${testId}`}
          onClick={onDismiss}
          type="button"
        >
          <XMarkMini className="w-4 h-4" />
          Close
        </button>
      </div>
    )
  ) : null;
};

export default UpcomingEventBanner;

UpcomingEventBanner.propTypes = {
  visible: PropTypes.bool.isRequired,
  testId: PropTypes.string.isRequired,
  dismissedText: PropTypes.string.isRequired,
  cookie: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
