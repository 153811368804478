import React from "react";

import CurrentContent from "../CurrentContent";
import PlacedOutsideBanner from "../common/PlacedOutsideBanner";
import Navigation from "../Student/Navigation";
import UpcomingMopBanner from "../UpcomingMopBanner";
import UpcomingEopBanner from "../UpcomingEopBanner";
import GraduationBanner from "../common/GraduationBanner";

const StudentToday = () => [
  <style key="style">
    {`:root {
        --timer-sticky-top: 120px;
        --md-timer-sticky-top: 126px;
        --lg-timer-sticky-top: 65px;
      }`}
  </style>,
  <Navigation key="navigation" />,
  <UpcomingMopBanner key="upcoming-mop" />,
  <UpcomingEopBanner key="upcoming-eop" />,
  <PlacedOutsideBanner key="place-out" className="mb-6" />,
  <GraduationBanner key="graduation-banner" />,
  <CurrentContent key="content" />,
];

export default StudentToday;
