import React, { useMemo } from "react";
import Goal from "./Goal";
import Item from "./Item";
import PropTypes from "prop-types";

export default function ProgressGraph({ progressData }) {
  const left = useMemo(() => {
    return `${(progressData.goals.MOP / progressData.goals.EOP) * 100}%`;
  }, [progressData.goals.MOP, progressData.goals.EOP]);

  return (
    <div className={"my-6 pb-8 relative w-full"}>
      {progressData.progressItems.map(({ orderIndex, title, progress }, index) => (
        <Item
          title={title}
          progress={progress}
          max={progressData.goals.EOP}
          orderIndex={orderIndex}
          key={index}
        />
      ))}
      <div className="absolute top-0 left-[118px] bottom-[-15px] right-0 z-1">
        <Goal
          label="MOP Goal"
          goal={progressData.goals.MOP}
          style={{
            left,
            marginLeft: "-96px",
            bottom: "22px",
          }}
        />
        <Goal
          label="EOP Goal"
          goal={progressData.goals.EOP}
          style={{ right: 0, marginRight: "2px" }}
        />
      </div>
    </div>
  );
}

ProgressGraph.propTypes = {
  progressData: PropTypes.shape({
    goals: PropTypes.shape({
      MOP: PropTypes.number.isRequired,
      EOP: PropTypes.number.isRequired,
    }).isRequired,
    progressItems: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        progress: PropTypes.number.isRequired,
      })
    ).isRequired,
  }),
};
